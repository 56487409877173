/*
Das ist eine gute Idee allerdings bekomme ich sie nicht
via background-image: url('@{assetsPath}/images/icon--rightArrow.svg');
zum laufen

@paths : {
assets: "@/../../assets";
images: "@/../../assets/images";
}
*/
.background--grey {
  background-color: #2B2E34;
}
.background--blue {
  background-color: #0B2CC2;
}
.background--lighterBlue {
  background-color: #1478C2;
}
.background--gold {
  background-color: #C47806;
}
.background--lightGold {
  background-color: #C4950B;
}
.background--pink {
  background-color: #DD2C6C;
}
.background--white {
  background-color: #ffffff;
}
.color--grey {
  color: #2B2E34;
}
.color--blue {
  color: #0B2CC2;
}
.color--lighterBlue {
  color: #1478C2;
}
.color--gold {
  color: #C47806;
}
.color--lightGold {
  color: #C4950B;
}
.color--pink {
  color: #DD2C6C;
}
.color--white {
  color: #ffffff;
}
@media (min-width: 1023px ) {
}@media (max-width: 1023px ) {
}.AbBookingForm__inner {
  display: grid;
}
.AbBookingForm__inner > * {
  min-height: 0;
  min-width: 0;
}
@media (min-width: 1023px ) {
  .AbBookingForm__inner {
    grid-column-gap: 24px;
  }
}
@media (max-width: 1023px ) {
  .AbBookingForm__inner {
    grid-column-gap: 20px;
  }
}
@media (max-width: 750px ) {
  .AbBookingForm__inner {
    grid-column-gap: 10px;
  }
}
@media (min-width: 1023px ) {
  .AbBookingForm__inner {
    grid-template-columns: repeat(12, minmax(0, 1fr));
  }
}
@media (max-width: 1023px ) {
  .AbBookingForm__inner {
    grid-template-columns: repeat(8, 1fr);
  }
}
@media (max-width: 750px ) {
  .AbBookingForm__inner {
    grid-template-columns: repeat(4, 1fr);
  }
}
@media (min-width: 1023px ) {
  .AbBookingForm__form {
    grid-column-start: 3;
    grid-column-end: 11;
  }
}
@media (max-width: 1023px ) {
  .AbBookingForm__form {
    grid-column-start: 2;
    grid-column-end: 8;
  }
}
@media (max-width: 750px ) {
  .AbBookingForm__form {
    grid-column-start: 1;
    grid-column-end: 5;
  }
}
.AbBookingForm__formElms {
  display: grid;
  transition: all 0.2s ease;
}
.AbBookingForm__formElms > * {
  min-height: 0;
  min-width: 0;
}
@media (min-width: 1023px ) {
  .AbBookingForm__formElms {
    grid-column-gap: 24px;
  }
}
@media (max-width: 1023px ) {
  .AbBookingForm__formElms {
    grid-column-gap: 20px;
  }
}
@media (max-width: 750px ) {
  .AbBookingForm__formElms {
    grid-column-gap: 10px;
  }
}
@media (min-width: 1023px ) {
  .AbBookingForm__formElms {
    grid-template-columns: repeat(8, 1fr);
  }
}
@media (max-width: 1023px ) {
  .AbBookingForm__formElms {
    grid-template-columns: repeat(6, 1fr);
  }
}
@media (max-width: 750px ) {
  .AbBookingForm__formElms {
    grid-template-columns: repeat(4, 1fr);
  }
}
.AbBookingForm__formElms--isSubmiting {
  opacity: 0.5;
}
@media (min-width: 1023px ) {
  .AbBookingForm__formElm {
    grid-column-start: 1;
    grid-column-end: 9;
  }
}
@media (max-width: 1023px ) {
  .AbBookingForm__formElm {
    grid-column-start: 1;
    grid-column-end: 7;
  }
}
@media (max-width: 750px ) {
  .AbBookingForm__formElm {
    grid-column-start: 1;
    grid-column-end: 5;
  }
}
@media (min-width: 1023px ) {
  .AbBookingForm__formElm--zip {
    grid-column: span 3;
  }
}
@media (max-width: 1023px ) {
  .AbBookingForm__formElm--zip {
    grid-column: span 2;
  }
}
@media (max-width: 750px ) {
  .AbBookingForm__formElm--zip {
    grid-column: span 3;
  }
}
@media (min-width: 1023px ) {
  .AbBookingForm__formElm--city {
    grid-column: span 5;
  }
}
@media (max-width: 1023px ) {
  .AbBookingForm__formElm--city {
    grid-column: span 4;
  }
}
@media (min-width: 1023px ) {
  .AbBookingForm__formElm--country {
    grid-column: span 5;
  }
}
@media (max-width: 1023px ) {
  .AbBookingForm__formElm--country {
    grid-column: span 5;
  }
}
@media (max-width: 750px ) {
  .AbBookingForm__formElm--country {
    grid-column: span 4;
  }
}
@media (min-width: 1023px ) {
  .AbBookingForm__formElm--phone {
    grid-column: span 4;
  }
}
@media (max-width: 1023px ) {
  .AbBookingForm__formElm--phone {
    grid-column: span 3;
  }
}
@media (max-width: 750px ) {
  .AbBookingForm__formElm--phone {
    grid-column: span 4;
  }
}
@media (min-width: 1023px ) {
  .AbBookingForm__formElm--mail {
    grid-column: span 4;
  }
}
@media (max-width: 1023px ) {
  .AbBookingForm__formElm--mail {
    grid-column: span 3;
  }
}
@media (max-width: 750px ) {
  .AbBookingForm__formElm--mail {
    grid-column: span 4;
  }
}
@media (min-width: 1023px ) {
  .AbBookingForm__formElm--arrivalDate {
    grid-column: span 4;
  }
}
@media (max-width: 1023px ) {
  .AbBookingForm__formElm--arrivalDate {
    grid-column: span 3;
  }
}
@media (max-width: 750px ) {
  .AbBookingForm__formElm--arrivalDate {
    grid-column: span 4;
  }
}
@media (min-width: 1023px ) {
  .AbBookingForm__formElm--departureDate {
    grid-column: span 4;
  }
}
@media (max-width: 1023px ) {
  .AbBookingForm__formElm--departureDate {
    grid-column: span 3;
  }
}
@media (max-width: 750px ) {
  .AbBookingForm__formElm--departureDate {
    grid-column: span 4;
  }
}
@media (min-width: 1023px ) {
  .AbBookingForm__formElm--guests {
    grid-column: span 4;
  }
}
@media (max-width: 1023px ) {
  .AbBookingForm__formElm--guests {
    grid-column: span 4;
  }
}
@media (max-width: 750px ) {
  .AbBookingForm__formElm--guests {
    grid-column: span 4;
  }
}
.AbBookingForm__formElm--roomType {
  display: flex;
  align-items: center;
}
@media (min-width: 1023px ) {
  .AbBookingForm__formElm--roomType {
    grid-column: span 2;
  }
}
@media (max-width: 1023px ) {
  .AbBookingForm__formElm--roomType {
    grid-column: span 3;
  }
}
@media (max-width: 750px ) {
  .AbBookingForm__formElm--roomType {
    grid-column: span 4;
  }
}
.AbBookingForm__submitBtn {
  margin: 1.5em 0;
}
.AbBookingForm .BaseText,
.AbBookingForm .AbBookingForm__formElm--wishes {
  margin-bottom: 1em;
}
.AbBookingForm .BaseRadio,
.AbBookingForm .BaseCheckbox {
  margin-bottom: 0.5em;
}
.AbBookingForm .AbBookingForm__formElm--kfz {
  margin-top: 0.5em;
}
.AbBookingForm .AbBookingForm__formElm--wishes {
  margin-top: 1em;
}
.AbBookingForm .BaseCheckbox__label a {
  margin-top: 1px;
  margin-left: 0.3em;
  margin-right: 0.3em;
}
.AbBookingForm .BaseRadio,
.AbBookingForm .BaseCheckbox {
  --activeBackgroundColor: #0B2CC2;
}
.AbBookingForm .BaseText {
  --height: 3em;
  --border: 1px solid transparent;
}
.AbBookingForm .BaseTextarea {
  --height: 10em;
  --border: 1px solid transparent;
}
.AbBookingForm__successMessage {
  position: relative;
  transition: all 0.2s ease;
  opacity: 0;
}
.AbBookingForm__successMessage--isVisible {
  opacity: 1;
}
.AbBookingForm__successMessageCloseBtn {
  position: absolute;
  top: 0.5em;
  right: 0.5em;
  display: block;
  height: 1em;
  width: 1em;
  background-image: url('../assets/images/icon--cross.svg');
  background-size: contain;
}
.AbBookingForm__successMessage .AbMessageBox {
  padding-right: 3em;
}
