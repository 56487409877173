














































































































@import (reference) "@/less/vars.less";
@import (reference) "@/less/mixins.less";

*, :after, :before {
	margin: 0; padding: 0;
	box-sizing: border-box;
}
.hideIfEmpty { &:empty { display: none; } }

.MhSliderDots { // debug
	[showBorders3] & {
		background-color: fade( red, 20 );

		.MhSliderDots__dot { background-color: fade( red, 10 ); }
		//.MhSliderDots__dotInner { background-color: fade( red, 50 ); }
	}
}
.MhSliderDots { // vars
	--transitionDuration: .25s;
	--dot-height: .5em;
	--dot-width: .5em;
	--dot-borderRadius: 50%;
	--dot-margin: 1em 0.25em;
	--dot--activeColor: blue;
	--dot--inactiveColor: lightblue;
	/*
	--dot-active-border-color : lightblue;
	--dot-inactive-border-color : fade( black, 75 );
	--dot-hover-background-color : fade( black, 85 );
	*/
}
.MhSliderDots { // layout
	display: flex;
	justify-content: center;
}
.MhSliderDots { // styling
	padding: 0;

	&__dot {
		transition: all var(--transitionDuration) ease;
		display: flex;
		padding: var(--dot-margin);
		cursor: pointer;
	}
	&__dotInner {
		transition: inherit;
		height: var(--dot-height);
		width: var(--dot-width);
		border-radius: var(--dot-borderRadius);
		overflow: hidden;
		text-indent: 10em;
	}
	&__dot--isActive &__dotInner {
		background-color: var(--dot--activeColor);
	}
	&__dot--isInactive &__dotInner {
		background-color: var(--dot--inactiveColor);

	}
}

@media @mq[xs] {}
@media @mq[sm] {}
@media @mq[md] {}
@media @mq[dt] {}
@media @mq[lg] {}
@media @mq[xl] {}
