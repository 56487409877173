




















































































































































































































































































































































	@import (reference) "./vars.less";
	@import (reference) "./mixins.less";

	.BaseSelect {
		position: relative;
		width: 100%;
		user-select: none;

		&__inner {
			position: relative;
			height: @selectElm[height];
			background-color: @selectElm[backgroundColor];
			border-radius: @selectElm[borderRadius];
			border: @selectElm[border];
			color: inherit;
			cursor: pointer;
		}
		&__select {
			max-width: 100%;
			width: 100%;
			height: 100%;
			text-indent: @selectElm[paddingLeft];
			cursor: pointer;

			border: none;
			outline: none;
			background-color: transparent;
			-webkit-appearance: none;
    		-moz-appearance: none;
    		appearance: none;
			font: inherit;
			color: inherit;
			padding: 0;
			margin: 0;			
		}
		&__select--isUnset { // lighten "Choose ..."
			opacity: 0.5;
		}
		&__select--isUnset:hover {
			opacity: 0.65;
		}
		&__option {
			padding-left: @selectElm[paddingLeft];
			background-color: transparent;
			//-webkit-appearance: none;
			//-moz-appearance: none;
			//appearance: none;
		}
		&__icon {
			//background-color: fade(red, 60);

			position: absolute;
			top: 0; right: 0;
			height: @selectElm[height];
			width: 2em;
			opacity: 0.6;
			color: currentColor;

			display: flex;
			justify-content: center;
			align-items: center;
			pointer-events: none;

			&::before {
				font-size: 0.65em;
				line-height: 1em;
				content: "▼";
			}
		}
	}
	.BaseSelect:hover {		
		.BaseSelect__inner { border: @selectElm[hoverBorder]; }
		.BaseSelect__icon { opacity: 1; }
	}
	.BaseSelect--isMultiple {
		.BaseSelect__inner {
			height: auto;
			padding: 0;
			/*
			min-height: @selectElm[multipleMinHeight];
			max-height: @selectElm[multipleMaxHeight];
			overflow: hidden;
			overflow-y: auto;
			*/
		}
		.BaseSelect__select { // hidden and replaced by .BaseSelect__optionsList
			display: none;
		}
		.BaseSelect__filterBar {
			position: relative;
			display: flex;
			justify-content: flex-end;
			align-items: center;
			padding: 0 0.25em;
			height: @selectElm[height];
			border-bottom: @selectElm[border];
			background-color: @selectElm[filterBarBackgroundColor];
			
			&CountSelected,
			&Input,
			&CountResults {
				font-size: 0.85em;
			}
			&Input {
				max-width: 100%;
				width: 200px;
				border-radius: @selectElm[height];
				//width: 80%;
				height: @selectElm[height]*0.75;
				text-indent: @selectElm[paddingLeft]; 
				//text-indent: var(--paddingLeft);
				//cursor: pointer;

				transition: all 0.2s ease;
				border: none;
				outline: none;
				background-color: white;
				-webkit-appearance: none;
				-moz-appearance: none;
				appearance: none;
				font-family: inherit;
				color: inherit;
				padding: 0;
				margin: 0;
				
				&::placeholder { 				
					opacity: 0.35;
					color: currentColor;
				}
				&:focus {
					outline: none;
					opacity: 1;
					width: 100%;
									
					&::placeholder { 
						//opacity: 0;
					}
				}
			}
			&CountResults {
				//background-color: fade( red, 20 );
				background-color: white;
				
				position: absolute;
				top: 0.4em; bottom: 0.4em; right: 0.25em;
				display: flex;
				align-items: center;
				padding: 0 0.75em;
				color: fade( black, 50 );
				border-top-right-radius: 1em;
				border-bottom-right-radius: 1em;
			}
			&CountSelected {
				display: none !important;
				//background-color: fade( red, 20 );
				
				display: flex;
				justify-content: center;
				border-radius: 1em;
				width: 2em;
				background-color: @selectElm[activeBackgroundColor];
				color: @selectElm[activeForegroundColor];
			}
		}
		.BaseSelect__optionsList {
			min-height: @selectElm[multipleMinHeight];
			max-height: @selectElm[multipleMaxHeight];
			overflow: hidden;
			overflow-y: auto;
		}
		/*
		.BaseSelect__option { // hidden and replaced by .BaseSelect__optionsItem
			height: @selectElm[height];
			display: flex;
			align-items: center;

			&:not(:first-child){ border-top: @selectElm[border]; }
		}
		.BaseSelect__option--label {
			display: none;
		}
		*/
		
		.BaseSelect__optionsItem {
			height: @selectElm[height];
			padding-left: @selectElm[paddingLeft];
			display: flex;
			align-items: center;
			white-space: nowrap;
			overflow: hidden;
			//text-overflow: ellipsis;
			transition: all 0.1s ease;
			
			&:not(:first-child){ border-top: @selectElm[border]; }
			//&::before { content: "☑" }
			&::before { 
				//content: "☐";
				//padding-right: 0.25em; 
				//font-size: 1.2em;
			}
		}
		.BaseSelect__optionsItem--isSelected {
			background-color: @selectElm[activeBackgroundColor];
			color: @selectElm[activeForegroundColor];
		}
		.BaseSelect__optionsItem--isHidden {
			//color: red;
			height: 0px;
			margin-top: -1px;
			border-color: transparent !important;
			background-color: transparent !important;
		}
		.BaseSelect__checkbox {
			.checkboxMixin();
			
			margin-right: 0.5em;
		}
	}
