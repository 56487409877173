/*
Das ist eine gute Idee allerdings bekomme ich sie nicht
via background-image: url('@{assetsPath}/images/icon--rightArrow.svg');
zum laufen

@paths : {
assets: "@/../../assets";
images: "@/../../assets/images";
}
*/
.background--grey {
  background-color: #2B2E34;
}
.background--blue {
  background-color: #0B2CC2;
}
.background--lighterBlue {
  background-color: #1478C2;
}
.background--gold {
  background-color: #C47806;
}
.background--lightGold {
  background-color: #C4950B;
}
.background--pink {
  background-color: #DD2C6C;
}
.background--white {
  background-color: #ffffff;
}
.color--grey {
  color: #2B2E34;
}
.color--blue {
  color: #0B2CC2;
}
.color--lighterBlue {
  color: #1478C2;
}
.color--gold {
  color: #C47806;
}
.color--lightGold {
  color: #C4950B;
}
.color--pink {
  color: #DD2C6C;
}
.color--white {
  color: #ffffff;
}
@media (min-width: 1023px ) {
}@media (max-width: 1023px ) {
}.AbLine {
  padding: 1em 0;
}
.AbLine__hr {
  margin: 0;
  padding: 0;
  border: none;
  height: 1px;
  background-color: #BFCCCC;
}
