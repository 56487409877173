












































































































































@import (reference) "./vars.less";
@import (reference) "./mixins.less";

.BaseRadio { // css vars
	--height                : @radioElm[height];
	--paddingLeft           : @radioElm[paddingLeft];
	--paddingRight          : @radioElm[paddingRight];
	--labelPadding          : @radioElm[labelPadding];
	--border                : @radioElm[border];
	--hoverBorder           : @radioElm[hoverBorder];
	--focusBorder           : @radioElm[focusBorder];
	--activeBackgroundColor : @radioElm[activeBackgroundColor];
	--activeForegroundColor : @radioElm[activeForegroundColor];
}
.BaseRadio {
	//outline: 1px solid fade( red, 50);
	display: inline-flex;
	align-items: flex-start;
	cursor: pointer;

	&__inner {
		//background-color: fade( red, 15 );

		position: relative;
		height: @radioElm[height]; height: var(--height);
		display: flex;
		align-items: center;
		padding-left: @radioElm[paddingLeft]; padding-left: var(--paddingLeft);
		padding-right: @radioElm[paddingRight]; padding-right: var(--paddingRight);
	}
	&__input {
		position: absolute;
		top: 0; left: 0;
		right: 0; bottom: 0;
		height: 100%; width: 100%; // needed correct dimensions for validation tooltip placement
		opacity: 0;
	}
	&__radio {
		.radioMixin();
	}
	&__label {
		min-height: @radioElm[height]; min-height: var(--height);
		padding: @radioElm[labelPadding]; padding: var(--labelPadding);

		display: flex;
		align-items: center;
		overflow: hidden;
		text-overflow: ellipsis;
	}
}
