













































































































































@import (reference) "./vars.less";
@import (reference) "./mixins.less";

.BaseCheckbox {	// css vars
	--height                : @checkboxElm[height];
	--paddingLeft           : @checkboxElm[paddingLeft];
	--paddingRight          : @checkboxElm[paddingRight];
	--labelPadding          : @checkboxElm[labelPadding];
	--border                : @checkboxElm[border];
	--hoverBorder           : @checkboxElm[hoverBorder];
	--focusBorder           : @checkboxElm[focusBorder];
	--activeBackgroundColor : @checkboxElm[activeBackgroundColor];
	--activeForegroundColor : @checkboxElm[activeForegroundColor];
}
.BaseCheckbox {
	//outline: 1px solid fade( red, 50);

	display: inline-flex;
	align-items: flex-start;
	cursor: pointer;

	&__inner {
		//background-color: fade( red, 15 );

		position: relative;
		height: @checkboxElm[height]; height: var(--height);
		display: flex;
		align-items: center;
		padding-left: @checkboxElm[paddingLeft]; padding-left: var(--paddingLeft);
		padding-right: @checkboxElm[paddingRight]; padding-right: var(--paddingRight);
	}
	&__input {
		position: absolute;
		top: 0; left: 0;
		right: 0; bottom: 0;
		height: 100%; width: 100%; // needed correct dimensions for validation tooltip placement
		opacity: 0;
	}
	&__checkbox {
		.checkboxMixin();
	}
	&__label {
		min-height: @checkboxElm[height]; min-height: var(--height);
		padding: @checkboxElm[labelPadding]; padding: var(--labelPadding);

		display: inline-block;
		display: flex;
		align-items: center;
		overflow: hidden;
		text-overflow: ellipsis;
	}
}
