








































































































































.MhHtmlEmbed {
	position: relative;

	&__embedWrapper {
		position: relative;
	}
	&__embedWrapper iframe {
		display: block; // prevents bottom margin
	}
	&__embedInner--hasAspectRatio {
		position: absolute;
		top: 0; left: 0; right: 0; bottom: 0;
		background-color: fade( black, 10 );

		& > * { display: none; } // hide everything but iframes

		iframe {
			position: absolute;
			top: 0; left: 0; right: 0; bottom: 0;
			height: 100%; width: 100%;
			display: block;
		}
	}
	&__embedInner > *:not(iframe) { display: none; }
}
