/*
Das ist eine gute Idee allerdings bekomme ich sie nicht
via background-image: url('@{assetsPath}/images/icon--rightArrow.svg');
zum laufen

@paths : {
assets: "@/../../assets";
images: "@/../../assets/images";
}
*/
.background--grey {
  background-color: #2B2E34;
}
.background--blue {
  background-color: #0B2CC2;
}
.background--lighterBlue {
  background-color: #1478C2;
}
.background--gold {
  background-color: #C47806;
}
.background--lightGold {
  background-color: #C4950B;
}
.background--pink {
  background-color: #DD2C6C;
}
.background--white {
  background-color: #ffffff;
}
.color--grey {
  color: #2B2E34;
}
.color--blue {
  color: #0B2CC2;
}
.color--lighterBlue {
  color: #1478C2;
}
.color--gold {
  color: #C47806;
}
.color--lightGold {
  color: #C4950B;
}
.color--pink {
  color: #DD2C6C;
}
.color--white {
  color: #ffffff;
}
@media (min-width: 1023px ) {
}@media (max-width: 1023px ) {
}.AbSlider .vueperSlides__track {
  background-color: rgba(0, 0, 0, 0.1);
}
.AbSlider .vueperSlides__arrow {
  transition: all 0.25s ease;
  position: absolute;
  top: calc(50% - 0.75em);
  width: 1.25em;
  height: 1.25em;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #0B2CC2;
  opacity: 0.5;
  background-color: white;
  padding: 15px 0;
}
.AbSlider .vueperSlides__inner:hover .vueperSlides__arrow {
  opacity: 1;
}
.AbSlider .vueperSlides__arrow--prev {
  left: 0.15em;
}
.AbSlider .vueperSlides__arrow--next {
  right: 0.15em;
}
.AbSlider .vueperSlides--atStart .vueperSlides__arrow--prev {
  display: none;
}
.AbSlider .vueperSlides--atEnd .vueperSlides__arrow--next {
  display: none;
}
.AbSlider .MhImage {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
.AbSlider .MhSliderDots--customStyling {
  --dot-height: 0.25em;
  --dot-width: 2em;
  --dot-borderRadius: 0%;
  --dot-margin: 0.75em 0.25em;
  --dot--activeColor: #0B2CC2;
  --dot--inactiveColor: #BFCCCC;
}
