@import (reference) "./vars.less";

.checkboxMixin(){
	position: relative;	
	height: @checkboxElm[height]*0.6; height: calc( var(--height) * 0.6 );	
	width: @checkboxElm[height]*0.6; width: calc( var(--height) * 0.6 );
	display: inline-flex;
	
	border: 1px solid transparent;
	
	// border + square
	&::before {
		position: absolute;
		top: 0; left: 0;
		bottom: 0; right: 0;
		border: @checkboxElm[border]; border: var(--border);
		border-radius: 20%;
		background-color: @checkboxElm[activeForegroundColor]; background-color: var(--activeForegroundColor);
		content: "";
	}
	&:hover:not(&--isSelected)::before {
		border: @checkboxElm[hoverBorder]; border: var(--hoverBorder);
	}
	
	// add checkmark
	&--isSelected {
		// border + square
		&::before {
			border: 1px solid @checkboxElm[activeBackgroundColor]; border: var(--activeBackgroundColor);
			background-color: @checkboxElm[activeBackgroundColor]; background-color: var(--activeBackgroundColor);
		}
		// checkmark
		&::after {
			position: absolute;
			top: 50%;
			left: 50%;
			height: @checkboxElm[height]*0.1; height: calc( var(--height) * 0.1 );
			width: @checkboxElm[height]*0.3; width: calc( var(--height) * 0.3 );
			
			border-left: 2px solid @checkboxElm[activeForegroundColor]; border-left: 2px solid var(--activeForegroundColor);
			border-bottom: 2px solid @checkboxElm[activeForegroundColor]; border-bottom: 2px solid var(--activeForegroundColor);
			transform: translateX(-50%) translateY(-65%) rotate(-45deg);
			content: "";
		}
	}

	// focus style
	*:focus + & span {
		@outset : 2px;
		position: absolute;
		top: -@outset; left: -@outset;
		right: -@outset; bottom: -@outset;
		border: @checkboxElm[focusBorder]; border: var(--focusBorder);
		border-radius: 4px;
	}
}
.radioMixin(){
	position: relative;	
	height: @radioElm[height]*0.6; height: calc( var(--height) * 0.6 );	
	width: @radioElm[height]*0.6; width: calc( var(--height) * 0.6 );
	display: inline-flex;
	
	border: 1px solid transparent;
	
	// border + circle
	&::before {
		position: absolute;
		top: 0; left: 0;
		bottom: 0; right: 0;
		border: @radioElm[border]; border: var(--border);
		border-radius: 50%;
		background-color: @radioElm[activeForegroundColor]; background-color: var(--activeForegroundColor);
		content: "";
	}
	&:hover:not(&--isSelected)::before {
		border: @radioElm[hoverBorder]; border: var(--hoverBorder);
	}
		
	// add inner circle
	&--isSelected {
		// border + square
		&::before {
			border: 1px solid @radioElm[activeBackgroundColor]; border: 1px solid var(--activeBackgroundColor);
		}
		// inner circle
		&::after {
			position: absolute;
			top: 50%;
			left: 50%;
			height: 0.55em;
			width: 0.55em;
			border-radius: 50%;			
			transform: translateX(-50%) translateY(-50%);
			background-color: @radioElm[activeBackgroundColor]; background-color: var(--activeBackgroundColor);
			content: "";
		}
	}

	// focus style
	*:focus + & span {
		@outset : 2px;
		position: absolute;
		top: -@outset; left: -@outset;
		right: -@outset; bottom: -@outset;
		border: @checkboxElm[focusBorder]; border: var(--focusBorder);
		border-radius: 50%;
	}
}
.clearBtnMixin(){
	
}
