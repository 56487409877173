











































































































@import (reference) "./vars.less";

.BaseTextarea { // css vars
	--height                : @textareaElm[height];
	--padding               : @textareaElm[padding];
	--borderRadius          : @textareaElm[borderRadius];
	--border                : @textareaElm[border];
	--hoverBorder           : @textareaElm[hoverBorder];
	--backgroundColor       : @textareaElm[backgroundColor];
}

.BaseTextarea {
	position: relative;
	width: 100%;

	&__inner {
		position: relative;
		//height: @textareaElm[height]; height: var(--height);
		width: 100%;
		background-color: @textareaElm[backgroundColor]; background-color: var(--backgroundColor);
		border-radius: @textareaElm[borderRadius]; border-radius: var(--borderRadius);
		border: @textareaElm[border]; border: var(--border);
		color: inherit;		
	}
	&__textarea {
		margin: 0;
		margin-bottom: -2px;
		max-width: 100%;
		width: 100%;
		//height: 100%;
		height: @textareaElm[height]; height: var(--height);
		padding: @textareaElm[padding]; padding: var(--padding);
		min-height: 4em;

		border: none;
		outline: none;
		background-color: transparent;
		-webkit-appearance: none;
		-moz-appearance: none;
		appearance: none;
		font: inherit;
		color: inherit;
		resize: vertical;
		
		&::placeholder { 				
			opacity: 0.5;
			color: currentColor;
		}
		&:focus {
			outline: none;
			opacity: 1;
							
			&::placeholder { 
				opacity: 0;
			}
		}
	}
}
.BaseTextarea:focus-within,
.BaseTextarea:hover {
	.BaseTextarea__inner {
		border: @textareaElm[hoverBorder];
	}
}
