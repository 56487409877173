/*
Das ist eine gute Idee allerdings bekomme ich sie nicht
via background-image: url('@{assetsPath}/images/icon--rightArrow.svg');
zum laufen

@paths : {
assets: "@/../../assets";
images: "@/../../assets/images";
}
*/
.background--grey {
  background-color: #2B2E34;
}
.background--blue {
  background-color: #0B2CC2;
}
.background--lighterBlue {
  background-color: #1478C2;
}
.background--gold {
  background-color: #C47806;
}
.background--lightGold {
  background-color: #C4950B;
}
.background--pink {
  background-color: #DD2C6C;
}
.background--white {
  background-color: #ffffff;
}
.color--grey {
  color: #2B2E34;
}
.color--blue {
  color: #0B2CC2;
}
.color--lighterBlue {
  color: #1478C2;
}
.color--gold {
  color: #C47806;
}
.color--lightGold {
  color: #C4950B;
}
.color--pink {
  color: #DD2C6C;
}
.color--white {
  color: #ffffff;
}
@media (min-width: 1023px ) {
}@media (max-width: 1023px ) {
}.AbHtmlEmbed {
  /*
	&__embedWrapper {
		position: relative;
		padding-bottom: 50%;

		& > * {
			position: absolute;
			top: 0; left: 0; bottom: 0; right: 0;
			height: 100%; width: 100%;
		}
	}
	*/
}
.AbHtmlEmbed .MhHtmlEmbedConsentWrapper__overlay {
  padding: 0.5em;
}
.AbHtmlEmbed .MhHtmlEmbedConsentWrapper__overlayTitle {
  font-size: 30px;
  line-height: 37px;
  line-height: 1.23em;
  padding-bottom: 0.5em;
}
@media (max-width: 1023px ) {
  .AbHtmlEmbed .MhHtmlEmbedConsentWrapper__overlayTitle {
    font-size: 25px;
    line-height: 31px;
    line-height: 1.24em;
  }
}
@media (max-width: 750px ) {
  .AbHtmlEmbed .MhHtmlEmbedConsentWrapper__overlayTitle {
    font-size: 25px;
    line-height: 31px;
    line-height: 1.24em;
  }
}
@media (min-width: 1023px ) {
  .AbHtmlEmbed .MhHtmlEmbedConsentWrapper__overlayText {
    font-size: 18px;
    line-height: 25px;
    line-height: 1.38em;
  }
}
@media (max-width: 1023px ) {
  .AbHtmlEmbed .MhHtmlEmbedConsentWrapper__overlayText {
    font-size: 18px;
    line-height: 25px;
    line-height: 1.38em;
  }
}
@media (max-width: 750px ) {
  .AbHtmlEmbed .MhHtmlEmbedConsentWrapper__overlayText {
    font-size: 16px;
    line-height: 24px;
    line-height: 1.5em;
  }
}
.AbHtmlEmbed .MhHtmlEmbedConsentWrapper__overlayButton {
  margin-top: 0.5em;
}
@media (min-width: 1023px ) {
  .AbHtmlEmbed .MhHtmlEmbedConsentWrapper__overlayButton {
    font-size: 18px;
    line-height: 25px;
    line-height: 1.38em;
  }
}
@media (max-width: 1023px ) {
  .AbHtmlEmbed .MhHtmlEmbedConsentWrapper__overlayButton {
    font-size: 18px;
    line-height: 25px;
    line-height: 1.38em;
  }
}
@media (max-width: 750px ) {
  .AbHtmlEmbed .MhHtmlEmbedConsentWrapper__overlayButton {
    font-size: 16px;
    line-height: 24px;
    line-height: 1.5em;
  }
}
.AbHtmlEmbed .MhHtmlEmbedConsentWrapper__overlayDisclaimer {
  font-size: 14px;
  line-height: 30px;
  padding-top: 3em;
  line-height: 1.2em;
}
